module.exports = function() {
            var utils = require("!!/Users/v625154/work/perspective/packages/perspective-webpack-plugin/src/js/utils.js");
            var workerPath = utils.publicPath(__webpack_public_path__) + "perspective.wasm.worker.js";
            if (utils.isCrossOrigin(__webpack_public_path__)) {
                return new Promise(function(resolve) {
                    utils.XHRWorker(workerPath, resolve);
                });
            } else {
                return new Promise(function(resolve) {
                    resolve(new Worker(workerPath)); 
                });
            }
        };